import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";
import { uuid } from "utils/uuid";

@Injectable({
    providedIn: 'root'
  })
  export class CustomMessageService {
    
    constructor(private messageService: MessageService){}

    error(options:{title?:string,message:string,styleClass?:string,sticky ?:boolean, closable?:boolean, icon?:string,keepPrevious?:boolean}){
      if (!options.keepPrevious) {
        this.messageService.clear();
      }
      this.messageService.add( { 
        severity: 'error',
        key: 'customService',
        sticky: options.sticky ?? false,
        summary: options.title ?? 'Error',
        styleClass: options.styleClass ?? 'custom-toast custom-error-toast',
        detail: options.message ?? 'Something went wrong, please contact with the support team',
        closable: options.closable,
        icon: options.icon,
        id:uuid(),
       } );
    }

    success(options:{title?:string,message:string,styleClass?:string,sticky ?:boolean,keepPrevious?:boolean}) {
      if (!options.keepPrevious) {
        this.messageService.clear();
      }
      this.messageService.add( { 
        severity: 'success',
        sticky: options.sticky ?? false,
        key: 'customService',
        summary: options.title ?? 'Success',
        detail:options.message,
        styleClass: options.styleClass ?? 'custom-toast custom-success-toast'
      });
    }

    warn(options:{title?:string,message:string,styleClass?:string,sticky ?:boolean,clear?:boolean,keepPrevious?:boolean}){
      if (!options.keepPrevious) {
        this.messageService.clear();
      }
      this.messageService.add( { 
        severity: 'warn',
        sticky: options.sticky ?? false,
        key: 'customService', 
        summary: options.title ?? 'Warning',
        detail: options.message,
        styleClass: 'custom-warn-toast',
        life: 120000 ,
      });
    }

    info(options:{title?:string,message:string,styleClass?:string,sticky ?:boolean,keepPrevious?:boolean, life?: number}){
      if (!options.keepPrevious) {
        this.messageService.clear();
      }
      this.messageService.add( { 
          severity: 'info', 
          key:'customService', 
          summary: options.title ?? 'Info', 
          detail: options.message,
          styleClass: 'custom-info-toast',
          life: options.life ?? 0,
        });
    }

    add(options:{ severity:string, sticky?:boolean,life?: number, summary:string, key:string, detail?:string }){
			this.messageService.add( options );
    }
  }
