import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                const skipRedirect = req.headers.get('Skip-403-Redirect') === 'true';

                if (error.status === 403 && !skipRedirect) {
                  const apiUrl = req.url;
                  this.router.navigate(['/access-denied'], { queryParams: { attemptedUrl: apiUrl } });
                }
                return throwError(() => error);
            })
        );
    }
}
