<!-- TODO ALI TO REVIEW -->
<app-main></app-main>
<title>{{title}}</title>
<!-- TODO:: replace  successToaster to successKey, in all places https://socienta.atlassian.net/browse/SOC-6429-->
<p-toast position="top-right" key="customService"  [styleClass]="'custom-toast'"></p-toast>
<p-toast position="center" key="errorToaster" [baseZIndex]="50000" [style]="{'min-width': '25vw'}">
	<ng-template let-message pTemplate="message">
		<div class="p-flex-column p-p-5 p-mt-2">
			<i class="pi pi-exclamation-triangle" style="font-size: 3.5rem"></i>
		</div>
		<div class="p-flex-column p-pt-5 p-mt-2" style="flex: 1">
			<div class="p-text-left p-pb-2">
				<h4 innerHtml="{{message.summary}}" class="p-pt-1"></h4>
				<p>{{message.detail}}</p>
			</div>
			<div class="p-text-right p-mt-5 p-mb-3">
				<button type="button" pButton label="Dismiss" class="custom-btn p-button-danger"
					(click)="messageService.clear()"></button>
			</div>
		</div>
	</ng-template>
</p-toast>
